.gallery-container {

    display: grid;
    gap: 1rem;

    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}

.gallery-container div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.gallery-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 1rem;
}