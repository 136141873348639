.grid {
    margin: 3rem 6rem;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-std {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex-std-res {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;

    @media screen and (max-width:768px) {
        flex-direction: column;
    }
}

.fixed {
    position: fixed;
}

.margin-top {
    margin-top: 1rem;
}

.margin-top-48 {
    margin-top: 3rem;
}

.margin-top-96 {
    margin-top: 6rem;
}

.margin-bottom {
    margin-bottom: 1rem;
}

.margin-bottom-96 {
    margin-bottom: 6rem;
}

.margin-bottom-48 {
    margin-bottom: 3rem;
}

.margin-x-48 {
    margin-left: 3rem;
    margin-right: 3rem;
}

.margin-x-96 {
    margin-left: 6rem;
    margin-right: 6rem;
}

.header-gap {
    margin-top: 5rem;
}