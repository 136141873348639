.popover-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    z-index: 1000;
}

.popover-container {
    width: 200px;
    height: 150px;
    margin-top: 75px;
    margin-right: 10px;
    background-color: var(--dark);
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.popover-overlay-dark {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    z-index: 1000;

    @media screen and (min-width: 768px) {
        justify-content: center;
        align-items: center;
    }
}

.popover-container-center {
    position: fixed;
    width: 100%;
    height: 10rem;
    bottom: 0;
    background-color: var(--dark);
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media screen and (min-width: 768px) {
        position: relative;
        justify-content: center;
        gap: 0.5rem;
        bottom: auto;
        max-width: 20rem;
        width: 20rem;
        height: 12rem;
        border-radius: 1rem;
    }
}