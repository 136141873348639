/* variables.scss */

:root {
    --primary: #f2ecdf;
    --secondary: #A49694;
    --accent: #5a513d;
    --light: #fff;
    --dark: #1c1915;

    --font-sans: "PT Sans Narrow", serif;
    --font-serif: "Lora", serif;
}