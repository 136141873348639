button.btn-std {
    cursor: pointer;
    font-family: var(--font-sans);
    padding: 0.7em 2em;
    margin: 0 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--primary);
    background-color: var(--dark);
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    outline: none;

    display: flex;
    align-items: center;

    .button-icon {
        height: 1.25rem;
        margin-right: .5rem;
        fill: currentColor;
    }
}

button.btn-std:hover {
    background-color: var(--primary);
    border: 1px solid var(--dark);
    color: var(--dark);
}

button.btn-std:active {
    background-color: var(--primary);
    color: var(--dark);
}

button.btn-primary {
    cursor: pointer;
    font-family: var(--font-sans);
    padding: 0.7em 2em;
    margin: 0 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--dark);
    background-color: var(--primary);
    border: none;
    border-radius: 45px;
    box-shadow: 0px .5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    outline: none;

    display: flex;
    align-items: center;
}

button.btn-large {
    min-height: 3rem;
    min-width: 10rem;
    font-size: 1.25rem !important;

    .button-icon {
        height: 2rem !important;
        margin-right: .5rem !important;
        fill: currentColor;
    }
}

@media screen and (max-width: 550px) {
    button.btn-std {
        font-size: 0.8rem;
        padding: 0.7em 1em;

        .button-icon {
            height: 1rem;
            margin-right: 4px;
        }
    }
}