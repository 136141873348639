.award-container {

    display: flex;
    justify-content: center;

    .award-box {

        display: flex;
        flex-direction: column;
        margin: 32px 32px;

        img {
            width: 150px;
            color: var(--primary);
            margin: 0 auto;
        }
    }
}