.note-box-container {
    background-color: var(--dark);
    margin: 1rem 20%;
    padding: 1rem;
    border-radius: 1rem;

    display: flex;

    .note-box-container-icon {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 1rem;

        img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
        }
    }

    p {
        color: var(--primary);
    }

    @media screen and (max-width: 550px) {
        margin: 1rem 1rem;

        .note-box-container-icon {
            width: 5rem;
        }
    }
}