.hero-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    width: 90%;
    height: 60vh;
    border-radius: 4rem;
    margin: 0 auto;
    margin-bottom: 5rem;

    @media screen and (max-width: 550px) {
        width: 95%;
        height: 70vh;
        border-radius: 2rem;
    }
}