.site-logo {
    background: var(--site-logo-url);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 70px;
    width: 200px;
}

.logo-std {
    background: var(--std-logo-url);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 10rem;
    width: 12rem;
}

@media screen and (max-width: 550px) {
    .site-logo {
        height: 70px;
        width: 150px;
    }

    .logo-std {
        height: 5rem;
        width: 6rem;
    }
}