.services-container {
    font-family: var(--font-sans);
    margin: 2rem 10rem;
}

.service-category {
    h2 {
        font-size: 24px;
        color: var(--dark);
    }
}

.service-category-subitems {
    display: grid;
    gap: 1.5rem;
    padding: 1rem;

    grid-template-columns: 1fr;

    @media (min-width: 550px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.service-item {
    border-radius: 1rem;
    border: 1px solid var(--accent);

    h3 {
        margin: 0.5rem;
    }

    p {
        margin: 0 1rem;
    }

    &:hover {
        cursor: pointer;
        color: var(--light);
        background: var(--dark);

        p,
        h3 {
            color: var(--light);
        }
    }
}

@media (max-width: 550px) {
    .services-container {
        margin: 2rem 1rem;
    }
}