.marketing-popover-overlay-dark {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.marketing-popover-container-center {
    position: relative;
    border-radius: .5rem;
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        max-width: 30rem;
    }
}

.marketing-popover-image {
    max-width: 100%;
    max-height: calc(100vh - 6rem);
    border-radius: .5rem;
}

p.marketing-popover-close {
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-shadow:
        1px 1px 0 var(--primary),
        -1px 1px 0 var(--primary),
        1px -1px 0 var(--primary),
        -1px -1px 0 var(--primary);
}