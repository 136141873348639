.site-header-parent {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10;
}

.site-header-scrolled {
    position: fixed;
}

.site-header {
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    background-color: var(--primary);
}

.site-header-logo-section {
    display: flex;
    align-items: center;
    color: var(--light);
    margin: 0 0 0 1rem;
}

.site-header-button-section {
    display: flex;
    align-items: center;
    justify-content: end;

    ul {
        display: flex;
        gap: 1rem;
        justify-content: start;
        margin: 0;
        margin-right: 1rem;
        padding-inline-start: 2.5rem;
        padding-inline-end: 2.5rem;

        li {
            display: flex;
            list-style: none;

            a {
                color: var(--dark);
                font-family: var(--font-sans);
                font-size: 1.25rem;
                letter-spacing: 1px;
                text-decoration: none;
                transition: color 0.5s ease-in-out, color 0.5s ease-in-out;
            }

            a:hover {
                color: var(--accent);
            }

            a.header-active-link {
                color: var(--secondary);
            }
        }
    }
}

.message-box {
    background-color: var(--dark);

    p {

        color: var(--primary);
        text-align: center;
        padding: 0.5rem 2rem;
        font: var(--font-serif);
    }
}

@media screen and (max-width: 768px) {

    .site-header-button-section {
        ul {
            gap: .5rem;
            padding-inline-start: 1rem;
            padding-inline-end: 0rem;
            margin-right: 0rem;

            li {
                a {
                    font-size: 1rem;
                }
            }
        }
    }
}