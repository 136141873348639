.site-footer {

    .site-footer-social-block {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        .insta-icon {
            margin-bottom: 1rem;
            height: 3rem;
            width: 3rem;
        }
    }

    .site-footer-top-block {

        display: flex;
        justify-content: space-evenly;
        background-color: var(--dark);
        height: 14rem;
        padding: 1rem 0;

        h2,
        p {
            color: var(--primary);
            font-family: var(--font-sans);
        }

        h2 {
            font-size: 1rem;
            font-weight: 500;
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-offset: .25rem;
        }

        .site-footer-top-block-logo-section {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media screen and (max-width: 768px) {

        h2,
        p {
            text-align: center;
        }

        .site-footer-top-block {
            flex-direction: column;
            height: auto;
        }
    }

}